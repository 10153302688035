<template>

<app-wrapper class="news" v-touch:swipe.right="onBackClick" v-touch:swipe.left="onForwardClick">

	<app-wrapper :content="true" :text="true" :inverse="true" class="services-content" :title="$state.page.news_title">

		<div class="news-content-list">

			<app-article class="news-content-list-item" v-for="(item, index) in items" :key="index" :wide="index === 0" :long="index <= 1 && window.is.desktop || index === 0" :item="item" :class="{'is-active': index === active, 'is-left': index < active, 'is-right': index > active}" />

		</div>

		<div class="pips" v-if="window.is.mobile && items.length > 1">
			<div v-for="(item, index) in items" :key="index" class="pips-item" :class="{'is-active': index === active}" v-on:click="onJumpClick(index)" />
		</div>

	</app-wrapper>

</app-wrapper>

</template>

<script>

import mixPips from '@/mixin/pips.js'

export default {

	mixins: [mixPips],

	computed: {

		items: function() {

			return this.$state.page.news
		
		}

	}

}

</script>

<style scoped>

.news {
	padding: 64px 0px 128px 0px;
	background-color: #485CC7;
}

.is-tablet .news {
	padding: 48px 0px 96px 0px;
}

.is-mobile .news {
	padding: 32px 0px 32px 0px;
}

.news-content-list {
	display: grid;
	margin-top: 64px;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 40px;
}

.is-tablet .news-content-list {
	margin-top: 48px;
	grid-gap: 20px;
	grid-template-columns: 1fr 1fr;
}

.is-mobile .news-content-list {
	margin-top: 32px;
	grid-gap: 20px;
	grid-template-columns: 1fr;
}

.news-content-list-item {
	transition: opacity 300ms ease-in-out;
}

.is-mobile .news-content-list-item:not(.is-active) {
	position: absolute;
	opacity: 0;
	z-index: 1;
}

.is-mobile .news-content-list-item.is-active {
	position: relative;
	opacity: 1;
	z-index: 2;
}

.pips {
	display: flex;
	margin-top: 30px;
}

.is-mobile .pips {
	justify-content: center;
}

.pips-item {
	border: 1px solid #fff;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 10px;
	transition: all 100ms linear;
	cursor: pointer;
}

.pips-item.is-active {
	background-color: #fff;
}

</style>
